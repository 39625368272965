import React, { useEffect, useState } from "react"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import markerIconPng from "leaflet/dist/images/marker-icon.png"
import positionMarker from "../images/positionMarker.png"
import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css';

export default function MuseumMap(userpos) {
    const [TheMap, setTheMap] = useState([])
    const [userPosition, setUserPosition] = useState([-75.257716, 0])


    useEffect(() => {

        async function loadingMap() {

            if (typeof window !== 'undefined') {
                //style={{ height: "68vh", width: "95vw" }}
                setTheMap(<MapContainer className="mapSize" center={[48.864716, 2.349014]} zoom={13} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />


                    < Marker position={[48.864603220958784, 2.29843234195393]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée d'Art Moderne de la Ville de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.880899, 2.333278]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de la vie romantique
                        </Popup >
                    </Marker >
                    < Marker position={[48.854808, 2.366232]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            Maison de Victor Hugo
                        </Popup >
                    </Marker >
                    < Marker position={[48.842793, 2.333457]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Zadkine
                        </Popup >
                    </Marker >
                    < Marker position={[48.84156094062689, 2.3177008959224517]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de La Poste
                        </Popup >
                    </Marker >
                    < Marker position={[48.850437, 2.344553]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée du Moyen Age - thermes et hôtel de Cluny
                        </Popup >
                    </Marker >
                    < Marker position={[48.868053, 2.326622]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de l'Orangerie des Tuileries
                        </Popup >
                    </Marker >
                    < Marker position={[48.855868, 2.31598]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Rodin
                        </Popup >
                    </Marker >
                    < Marker position={[48.86080432275963, 2.3522235406355176]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée national d'art moderne (centre national d’art et de culture Georges Pompidou)
                        </Popup >
                    </Marker >
                    < Marker position={[48.860294, 2.324768]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée national de la Légion d'honneur et des ordres de chevalerie
                        </Popup >
                    </Marker >
                    < Marker position={[48.865357, 2.357046]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée des arts et métiers
                        </Popup >
                    </Marker >
                    < Marker position={[48.857003, 2.338699]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée des monnaies et médailles – La Monnaie de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.836753, 2.390394]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée national de l'histoire et des cultures de l'immigration
                        </Popup >
                    </Marker >
                    < Marker position={[48.843834, 2.355199]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            galerie d’entomologie (muséum national d'histoire naturelle)
                        </Popup >
                    </Marker >
                    < Marker position={[48.861491, 2.358608]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de la Chasse et de la Nature
                        </Popup >
                    </Marker >
                    < Marker position={[48.86095, 2.355608]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée d'Art et d'Histoire du Judaïsme
                        </Popup >
                    </Marker >
                    < Marker position={[48.838805, 2.356007]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de l'Assistance Publique - Hôpitaux de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.855398, 2.280805]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            maison de Balzac
                        </Popup >
                    </Marker >
                    < Marker position={[48.866136805463356, 2.297248499625773]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            Palais Galliera, musée de la mode de la ville de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.86425, 2.293754]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée des Arts Asiatiques Guimet
                        </Popup >
                    </Marker >
                    < Marker position={[48.863218, 2.3333]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée des arts décoratifs (Union centrale des arts décoratifs)
                        </Popup >
                    </Marker >
                    < Marker position={[48.86079459806906, 2.3378156596817785]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée du Louvre
                        </Popup >
                    </Marker >
                    < Marker position={[48.862323, 2.281879]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée des monuments français
                        </Popup >
                    </Marker >
                    < Marker position={[48.858219, 2.312885]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée des plans-reliefs
                        </Popup >
                    </Marker >
                    < Marker position={[48.86812, 2.33823]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            Musée du Cabinet des Médailles de la Bibliothèque Nationale de France
                        </Popup >
                    </Marker >
                    < Marker position={[48.85699, 2.362856]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Carnavalet – histoire de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.858257, 2.361524]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Cognacq-Jay,musée du XVIIIe siècle de la ville de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.840614, 2.319773]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée du maréchal Leclerc de Hautecloque et de la Libération de Paris - musée Jean Moulin
                        </Popup >
                    </Marker >
                    < Marker position={[48.86609, 2.313763]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            petit Palais, musée des beaux-arts de la ville de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.854434, 2.335679]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Eugène Delacroix
                        </Popup >
                    </Marker >
                    < Marker position={[48.871845, 2.281997]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée d'Ennery (annexe musée Guimet)
                        </Popup >
                    </Marker >
                    < Marker position={[48.877878, 2.334383]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Gustave Moreau
                        </Popup >
                    </Marker >
                    < Marker position={[48.889306, 2.393807]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de la musique
                        </Popup >
                    </Marker >
                    < Marker position={[48.862548, 2.28222]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de l’homme (muséum national d'histoire naturelle)
                        </Popup >
                    </Marker >
                    < Marker position={[48.840814, 2.341091]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée du service de santé des Armées du Val-de-Grâce
                        </Popup >
                    </Marker >
                    < Marker position={[48.874991, 2.343088]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de la Franc-Maçonnerie
                        </Popup >
                    </Marker >
                    < Marker position={[48.887709, 2.340577]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de Montmartre
                        </Popup >
                    </Marker >
                    < Marker position={[48.87578, 2.31034]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            Musée Jacquemart-André
                        </Popup >
                    </Marker >
                    < Marker position={[48.861511, 2.29771]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée du Quai Branly - Jacques Chirac
                        </Popup >
                    </Marker >
                    < Marker position={[48.859157, 2.323672]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée d'Orsay
                        </Popup >
                    </Marker >
                    < Marker position={[48.86008, 2.35741]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            Musée de l'Histoire de France
                        </Popup >
                    </Marker >
                    < Marker position={[48.87358, 2.33202]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            Bibliothèque - musée de l'Opéra
                        </Popup >
                    </Marker >
                    < Marker position={[48.842168, 2.359933]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            Galeries de paléontologie et d'anatomie comparée (muséum national d'histoire naturelle)
                        </Popup >
                    </Marker >
                    < Marker position={[48.843955, 2.352176]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de l'Institut du Monde Arabe
                        </Popup >
                    </Marker >
                    < Marker position={[48.843017, 2.31883]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Bourdelle
                        </Popup >
                    </Marker >
                    < Marker position={[48.879693, 2.312194]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Cernuschi, musée des arts de l’Asie de la ville de Paris
                        </Popup >
                    </Marker >
                    < Marker position={[48.830264, 2.340376]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée d’art et d’histoire de l’hôpital Sainte-Anne
                        </Popup >
                    </Marker >
                    < Marker position={[48.858219, 2.312885]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée de l'Armée
                        </Popup >
                    </Marker >
                    < Marker position={[48.86425, 2.293754]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée national de La Marine
                        </Popup >
                    </Marker >
                    < Marker position={[48.883045, 2.307689]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Jean-Jacques Henner
                        </Popup >
                    </Marker >
                    < Marker position={[48.8597, 2.362644]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Picasso
                        </Popup >
                    </Marker >
                    < Marker position={[48.878849, 2.312855]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            musée Nissim de Camondo (Union centrale des arts décoratifs)
                        </Popup >
                    </Marker >
                    < Marker position={[48.841491, 2.355989]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            galerie de minéralogie et de géologie (muséum national d'histoire naturelle)
                        </Popup >
                    </Marker >
                    < Marker position={[48.841491, 2.355989]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} >
                        < Popup >
                            grande galerie de l’évolution (muséum national d'histoire naturelle)
                        </Popup >
                    </Marker >
                    < Marker position={userPosition} icon={new Icon({ iconUrl: positionMarker, iconSize: [25, 25], iconAnchor: [12, 25] })} >
                        < Popup >
                            Vous êtes ici
                        </Popup >
                    </Marker >

                </MapContainer>)
            }
        }

        loadingMap()


    }, [userPosition]);

    function changeUserPosition() {
        setUserPosition(userpos.userpos)
    }

    if (userpos.userpos !== userPosition) {
        changeUserPosition()
    }

    return (
        <div style={{ display: "flex", justifyContent: "center", margin: 0, padding: 0 }}> {TheMap}</div>

    )
}