import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import MuseumMap from "../components/MuseumMap"


function Museum(props) {
    const [museumPage, setMuseumPage] = useState([])
    const [userLocation, setUserLocation] = useState([-75.257716, 0])
    const [bgColor1, setBgColor1] = useState("rgb(75,75,75)")
    const [bgColor2, setBgColor2] = useState("rgb(75,75,75)")

    const [render, setRender] = useState(
        <div><MuseumMap userpos={userLocation} />
            <div style={{ display: "flex", justifyContent: "center" }}>
                {/* this is a test button make on the fly that need to become a reusable component */}
                <div style={{ backgroundColor: "rgb(200, 0, 0)", color: "rgb(255,255,255)", borderRadius: 5, padding: 10, margin: 5 }} onClick={() => localisation()}  > <p>Géolocalisez moi</p></div>
            </div></div>)

    useEffect(() => {
        async function loadingData() {
            const response = require("../data/museum.json")

            setMuseumPage(response.records)
        }

        loadingData()

    }, []);
    const museumList = museumPage.map((museum, i) => {
        // const description = museum?.fields.themes
        // if (description.length > 150) {
        //     description = description.slice(0, 150) + '...'
        // }


        //window.innerWidth for desktop and screen.width for devices
        let deviceWidth
        if (window.innerWidth > 600) deviceWidth = "30vw"
        else deviceWidth = "98vw"
        const https = "https://"
        const museumUrl = museum?.fields.url_m
        const finalUrl = https + museumUrl

        return (< div key={i} style={{ display: "flex", flexDirection: "column", height: "48vh", width: deviceWidth, minWidth: deviceWidth, backgroundColor: "rgb(235,235,235)", borderRadius: "10px", marginBottom: "10px", marginLeft: "5px", marginRight: "5px", padding: 5 }}>
            {/* on mets la premiere lettre en maj */}
            <h3 style={{ margin: 5 }}>{museum?.fields.nomoff.charAt(0).toUpperCase() + museum?.fields.nomoff.slice(1)}</h3>
            <p style={{ margin: 5 }}>{museum?.fields.themes}</p>
            {/* <p style={{ margin: 5 }}>{museum.fields.atout}</p> */}
            <p style={{ margin: 5 }}>{museum?.fields.adrl1_m}, {museum?.fields.cp_m} Paris</p>
            <a style={{ margin: 5 }} external href={finalUrl} target="_blank" rel="noreferrer"> {museum?.fields.url_m}</a>
        </div >)

    })
    function localisation() {
        // console.log("hello")
        navigator.geolocation.getCurrentPosition(getPosition)

        if (!navigator.geolocation) {
            console.log("Your browser doesn't support geolocation feature!")
        } else {

            navigator.geolocation.getCurrentPosition(getPosition)

        };

        function getPosition(position) {


            const lat = position.coords.latitude
            const long = position.coords.longitude
            // const accuracy = position.coords.accuracy

            setUserLocation([lat, long])

        }
    }

    async function handleMuseumRender(renderType) {
        if (renderType === "Carte") {
            setRender(<div><MuseumMap userpos={userLocation} />

                <div style={{ display: "flex", justifyContent: "center" }}>
                    {/* this is a test button make on the fly  */}
                    <div style={{ backgroundColor: "rgb(200, 0, 0)", color: "rgb(255,255,255)", borderRadius: 5, padding: 10, margin: 5 }} onClick={() => localisation()}  > <p>Géolocalisez moi</p></div>

                    {/* <Button defaultBg="rgb(200, 0, 0)" click={()=> localisation()} title="Géolocalisez moi" /> */}
                </div></div>)
        } else if (renderType === "Liste") {
            setRender(<div style={mainStyle}> <div style={displayListStyle}>{museumList}</div></div>)
        }
    }

    return (
        <Layout>
            <Seo title="Museum" />
            <h2 style={titleStyle}>Les musées parisiens</h2>

            {/* faire un composant avec les bonnes props pour ces 2 boutons  */}
            <div style={{ display: "flex", justifyContent: "center" }}>

                <div style={{ backgroundColor: bgColor1, color: "rgb(255,255,255)", padding: 10, borderRadius: 5, marginLeft: 5, marginBottom: 3 }} id="btn" type="button" onMouseEnter={() => setBgColor1("rgb(0,0,0)")} onMouseLeave={() => setBgColor1("rgb(75, 75, 75)")} onClick={() => { handleMuseumRender("Carte") }}>
                    Carte
                </div>
                <div style={{ backgroundColor: bgColor2, color: "rgb(255,255,255)", padding: 10, borderRadius: 5, marginLeft: 5, marginBottom: 3 }} id="btn" type="button" onMouseEnter={() => setBgColor2("rgb(0,0,0)")} onMouseLeave={() => setBgColor2("rgb(75, 75, 75)")} onClick={() => { handleMuseumRender("Liste") }}>
                    Liste
                </div>

            </div>
            {render}
        </Layout>
    )
}
const titleStyle = { textAlign: "center", color: "rgb(235,235,235)" }
const mainStyle = { display: "flex", width: "99vw", flexWrap: "wrap", justifyContent: "center" }
const displayListStyle = { display: "flex", flexWrap: "wrap", justifyContent: "center", flexDirection: "row" }
export default Museum